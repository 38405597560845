/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import slugify from "slugify"

function SEO({
  description,
  lang,
  image,
  meta,
  keywords,
  title,
  pathname,
  imageUrl,
  readingTime,
  publishedTime,
  UID,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaUrl = title
    ? UID
      ? `${site.siteMetadata.siteUrl}/${UID}/`
      : `${site.siteMetadata.siteUrl}/${slugify(title.toLowerCase())}/`
    : site.siteMetadata.siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          "http-equiv": "content-type",
          content: "text/html",
          charset: "UTF-8",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `title`,
          content: title,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: imageUrl ? `${site.siteMetadata.siteUrl}${imageUrl}` : null,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          imageUrl
            ? [
                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                },
                {
                  property: `og:image:alt`,
                  content: title,
                },
              ]
            : [
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
              ]
        )
        .concat(
          readingTime
            ? [
                {
                  name: `twitter:label1`,
                  content: `Reading time`,
                },
                {
                  name: `twitter:data1`,
                  content: readingTime,
                },
                {
                  name: `og:type`,
                  content: `article`,
                },
                {
                  name: `article:published_time`,
                  content: publishedTime,
                },
              ]
            : [
                {
                  property: `og:type`,
                  content: `website`,
                },
              ]
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  pathname: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.object,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
